import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M75.2 12.8352H43.744c-0.688 0-1.216 0.56-1.216 1.216v27.542c0.173 6.069-0.096 13.245-0.784 14.658c-0.056 0.073-0.112 0.166-0.152 0.241c-0.896 1.87-4.032 3.78-7.274 3.78c-3.524 0-7.737-2.73-8.898-6.84a1.216 1.216 0 0 0-1.582-0.785l-11.44 3.358c-0.592 0.177-0.952 0.784-0.845 1.393c0.005 0.035 0.014 0.072 0.019 0.1c1.297 10.651 9.807 16.463 21.343 16.463c12.824 0 21.710-6.574 22.934-16.192c0.008-0.028 0.018-0.052 0.021-0.118c0.65-7.08 0.721-15.92 0.721-15.94V15.104c0.009-0.688-0.568-1.216-1.0-1.216z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
