import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      color: var(--green);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Smart Contract', 'Web3.js / Ethers.js', 'HTML / CSS / jQuery', 'Javascript', 'Typescript', 'Python', 'Solidity',
    'Rust', 'React / Redux', 'Next.js', 'Node.js', 'Express.js', 'Tailwind CSS', 'Rest API', 'Cosmwasm',
    'Hardhat / Truffle', 'Postgresql', 'MongoDB', 'AWS', 'Azure', 'Git', 'Agile Methodologies', 'Etherium / EVMs', 'ICO Development',
    'DeFi Protocols', 'DEX Infrastructure', 'Smart Contract Optimization', 'Crypto Wallet Integration', 'CI/CD', 'Cosmos Network Integration',
    'Web Performance Optimization', 'DevOps Methodologies', 'Test-Driven Development (TDD)', 'Security Best Practices', 'SEO Strategies', 'Governance Models'
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
            # Profile Introduction<br/>
I am a seasoned Full Stack Blockchain Engineer with a robust background in both blockchain development and full stack web development. Over the past 5 years, I have specialized in blockchain technologies, successfully completing over 100 projects that exemplify my expertise and dedication to this evolving field. With a decade of experience in full stack web development, I bring a comprehensive understanding of both frontend and backend systems, enabling me to deliver end-to-end solutions that meet diverse client needs.
            </p>
            <p>
            # Specialization:<br/>
1) Blockchain Development<br/>
- Proficient in CosmWasm, facilitating the creation of scalable decentralized applications.<br/>
- Extensive experience in Solidity and Rust, allowing for smart contract development and deployment on various blockchain platforms.<br/>
- Proven ability to build and maintain DApps, ensuring seamless user interactions and enhanced functionality.<br/>
2) Full Stack Web Development<br/>
- Strong command of frontend technologies, including HTML, CSS, JavaScript, and popular frameworks, to create engaging and responsive user interfaces.<br/>
- Skilled in backend development with Node.js, Express, and other technologies, ensuring robust server-side logic and API integrations.<br/>
- Expertise in web3 principles, enabling the integration of blockchain functionalities with traditional web services.<br/>
            </p>

            <p>
            # Services Offered:<br/>
- Complete DApp development, encompassing both frontend and backend components, tailored to client specifications.<br/>
- API integrations to streamline connectivity between blockchain networks and existing web applications.<br/>
- Consultation and support for blockchain implementation, ensuring best practices are followed for security and scalability.
            </p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
