import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 20px;
    padding: 20px;
    display: flex;  
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        My inbox is always open. Whether you have a question or just want to say
        hello, I'll try my best to get back to you! Feel free to mail me about any
        relevant job updates.
      </p>

      <a className="email-link" href="mailto:james.carlton.0118@gmail.com">
        Mail: james.carlton.0118@gmail.com
      </a>
      <a className="email-link" href="https://join.skype.com/invite/oU5YHic7xCJh">
        Skype: live:.cid.d442bb835e35df9f
      </a>
      <a className="email-link" href="https://t.me/EthanBitsmith">
        Telegram id: @EthanBitsmith
      </a>
      <a className="email-link" href="https://discord.com/channels/@me">
        Discord id: james.carlton
      </a>
      <a className="email-link" href="https://calendly.com/james-carlton-0118/30min">
        Book a Call
      </a>
    </StyledContactSection>
  );
};

export default Contact;
